//dev server API

// export const BASE_URL = 'https://markdowntoday.ahmedul.com/api';
// export const IMG_BASE_URL = 'https://markdowntoday.ahmedul.com/assets/images';

//local server API

// export const BASE_URL = 'https://ap.markdowntoday2.ahmedul.com/api';
// export const IMG_BASE_URL = 'https://ap.markdowntoday2.ahmedul.com/assets/images';

export const BASE_URL = 'https://ap.markdowntoday2.ahmedul.com/api';
export const IMG_BASE_URL = 'https://ap.markdowntoday2.ahmedul.com/assets/images';